import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from '../navbar/navbar.component';
import { FooterComponent } from '../footer/footer.component';
import { BackendService } from '../../../service/backend.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-base',
  standalone: true,
  imports: [CommonModule ,RouterOutlet, NavbarComponent, FooterComponent],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss'
})
export class BaseComponent {
  showLoading = false;
  loadingText = "";
  constructor(public bs: BackendService) {
    this.bs.showLoading$.subscribe((event: any) => {
      this.showLoading = event?.show;
      this.loadingText = event?.text;
    })
   }

}
