<div class="container-fluid" data-bs-theme="light">
  @if(showLoading) {
  <div class="backdrop">
    <div class="spinner-container">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-text">{{loadingText}}</div>
    </div>
  </div>
  }
  <app-navbar></app-navbar>
  <div class="content h-100" style="padding-bottom:50px;">
    <router-outlet />
  </div>
  <app-footer></app-footer>
</div>
