import { Routes } from '@angular/router';
import { AccountComponent } from './views/pages/account/account.component';
import { BaseComponent } from './views/layout/base/base.component';
import { HomeComponent } from './views/pages/home/home.component';
import { KostenmarkeComponent } from './views/pages/kostenmarke/kostenmarke.component';
import { LoginComponent } from './views/pages/auth/login/login.component';
import { SubscriptionsComponent } from './views/pages/account/component/subscriptions/subscriptions.component';
import { SuccessComponent } from './views/pages/payment/success/success.component';
import { CancelComponent } from './views/pages/payment/cancel/cancel.component';
import { SettingsComponent } from './views/pages/account/component/settings/settings.component';
import { InvoicesComponent } from './views/pages/account/component/invoices/invoices.component';
import {
  AngularFireAuthGuard,
  canActivate,
  customClaims,
  emailVerified,
  redirectUnauthorizedTo,
  loggedIn,
} from '@angular/fire/compat/auth-guard';
import { AuthGuard } from '@angular/fire/auth-guard';
import { map, pipe } from 'rxjs';
import { VerifyComponent } from './views/pages/auth/verify/verify.component';
import { ResetPasswordComponent } from './views/pages/auth/reset-password/reset-password.component';
import { RegisterComponent } from './views/pages/auth/register/register.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { VerifyEmailComponent } from './views/pages/auth/verify-email/verify-email.component';

const adminOnly = () =>
  pipe(
    customClaims,
    map((claims: any) => {
      return claims.roles && claims.roles.includes('admin') ? true : ['error'];
    })
  );

const redirectUnauthorizedToLogin = (user: any) =>
  redirectUnauthorizedTo(['auth/login']);

const signedOutOnly = () =>
  pipe(
    map((user: firebase.default.User | null) => {
      if (!user) {
        return true;
      }
      return ['home'];
    })
  );

const verifiedAndSignedInOnly = () =>
  pipe(
    map((user: firebase.default.User | null) => {
      if (!user) {
        return ['auth/login'];
      }
      return user.emailVerified === true ? true : ['auth/verifiy'];
    })
  );

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        //load lazy component
        loadComponent: () =>
          import('./views/pages/home/home.component').then(
            (m) => m.HomeComponent
          ),

        //component: HomeComponent,
        ...canActivate(verifiedAndSignedInOnly),
      },
      {
        path: 'kostenmarke',
        loadComponent: () =>
          import('./views/pages/kostenmarke/kostenmarke.component').then(
            (m) => m.KostenmarkeComponent
          ),
        //component: KostenmarkeComponent,
        ...canActivate(verifiedAndSignedInOnly),
      },
      {
        path: 'account',
        loadComponent: () =>
          import('./views/pages/account/account.component').then(
            (m) => m.AccountComponent
          ),
        //component: AccountComponent,
        ...canActivate(redirectUnauthorizedToLogin),
        children: [
          {
            path: 'settings',
            loadComponent: () =>
              import(
                './views/pages/account/component/settings/settings.component'
              ).then((m) => m.SettingsComponent),
            //component: SettingsComponent,
            //...canActivate(redirectUnauthorizedToLogin),
          },
          {
            path: 'abonnements',
            loadComponent: () =>
              import(
                './views/pages/account/component/subscriptions/subscriptions.component'
              ).then((m) => m.SubscriptionsComponent),
            //component: SubscriptionsComponent,
            ...canActivate(verifiedAndSignedInOnly),
          },
          {
            path: 'invoices',
            loadComponent: () =>
              import(
                './views/pages/account/component/invoices/invoices.component'
              ).then((m) => m.InvoicesComponent),
            //component: InvoicesComponent,
            ...canActivate(verifiedAndSignedInOnly),
          },
        ],
        //loadChildren: () => import('./layout/pages/account/account.component').then(m => m.AccountComponent)
      },
      {
        path: 'payment/success',
        ...canActivate(verifiedAndSignedInOnly),
        loadComponent: () =>
          import('./views/pages/payment/success/success.component').then(
            (m) => m.SuccessComponent
          ),
        //component: SuccessComponent
      },
      {
        path: 'payment/cancel',
        loadComponent: () =>
          import('./views/pages/payment/cancel/cancel.component').then(
            (m) => m.CancelComponent
          ),
        //component: CancelComponent,
        ...canActivate(verifiedAndSignedInOnly),
      },
    ],
  },
  {
    path: 'auth/login',
    loadComponent: () =>
      import('./views/pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      ),
    ...canActivate(signedOutOnly),
    //component: LoginComponent
  },
  {
    path: 'auth/register',
    loadComponent: () =>
      import('./views/pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      ),
    //component: RegisterComponent
  },
  {
    path: 'auth/verifiy',
    loadComponent: () =>
      import('./views/pages/auth/verify/verify.component').then(
        (m) => m.VerifyComponent
      ),
    //component: VerifyComponent
  },
  {
    path: 'auth/verify-email',
    component: VerifyEmailComponent
  },
  {
    path: 'auth/reset-password',
    loadComponent: () =>
      import('./views/pages/auth/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
    //component: ResetPasswordComponent
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
