import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent {

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) {
    console.log("params", this.route.snapshot.paramMap);
    this.route.queryParamMap.subscribe(async query => {
      console.log("queryParams", query);
      const oobCode = query.get('oobCode');
      console.log("oobCode", oobCode);
      
      /* if(oobCode) {
        this.auth.applyVerification(oobCode)
        .then(async () => {
          await this.router.navigate(['home']);
        })
        .catch(async err => {
          console.log('ERROR VERIFY', err);
          await this.auth.logout();
          await this.router.navigate(['auth/login'])
        })
      } else {
        await this.auth.logout();
        await this.router.navigate(['auth/login']);
      } */

    })
  }

}


