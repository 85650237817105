<nav class="navbar navbar-expand-lg bg-body-tertiary mb-1">
  <div class="container-fluid">
    <!-- <a class="navbar-brand" [routerLink]="['/home']">Welcome {{auth.user?.email!.split("@")[0]}}</a> -->
    @if(contactData) {
    <a class="navbar-brand" [routerLink]="['/home']"><span class="me-2">Willkommen</span>
      {{contactData?.contact?.name}}</a>
    } @else {
    <a class="navbar-brand" [routerLink]="['/home']">Willkommen</a>
    }
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        @if (auth.user) {
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" [routerLink]="['/home']" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent">Home</a>
        </li>
        <!-- @if(subscriptions$ | async; as subs) { -->
        @if(office?.subscription?.status == "active") {
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/kostenmarke']" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent">Kostenmarke</a>
        </li>
        }
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Account
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="['/account/settings']" routerLinkActive="router-link-active"
                data-bs-toggle="collapse">Einstellungen</a></li>
            <li><a class="dropdown-item" [routerLink]="['/account/abonnements']" routerLinkActive="router-link-active"
                data-bs-toggle="collapse">Abonnements</a></li>
            <li><a class="dropdown-item" [routerLink]="['/account/invoices']" routerLinkActive="router-link-active"
                data-bs-toggle="collapse">Rechnungen</a></li>

            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" aria-disabled="true" (click)="auth.logout()" data-bs-toggle="dropdown">Logout</a>
            </li>
          </ul>
        </li>
        } @else {
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/auth/login']" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent">Login</a>
        </li>
        }
      </ul>
    </div>
  </div>
</nav>
