import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withHashLocation, withPreloading } from '@angular/router';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth, initializeAuth } from '@angular/fire/auth';
import { routes } from './app.routes';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideNgxStripe } from 'ngx-stripe';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { enableMultiTabIndexedDbPersistence, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules), withHashLocation()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const app = getApp();
      //const dbName = environment.production ? '(default)' : 'staging';
      const dbName = environment.production ? '(default)' : '(default)';
      const providedFirestore = initializeFirestore(app, {}, dbName);
      return providedFirestore;
    }),
    provideFunctions(() => getFunctions(getApp(), 'europe-west3')),
    provideDatabase(() => getDatabase()),
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    provideNgxStripe(),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators.auth ? ['http://localhost:9099'] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators.firestore ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators.http ? ['localhost', 5001] : undefined },
  ]
};
